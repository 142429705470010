// L.drawVersion = '0.4.2';
// L.Draw = {};
export default {
	draw: {
		toolbar: {
			actions: {
				title: "Чизичши тўхтатиш", //'Cancel drawing',
				text: "Бекор қилиш" //'Cancel'
			},
			finish: {
				title: "Чизишни тугатиш",
				text: "Тугатиш"
			},
			undo: {
				title: "", //'Delete last point drawn',
				text: "Охирги нуқтани ўчириш" //'Delete last point'
			},
			buttons: {
				polyline: "Чизиқ чизиш", //'Draw a polyline',
				polygon: "Полигон чизиш", //'Draw a polygon',
				rectangle: "Тўртбурчак чизиш", //'Draw a rectangle',
				circle: "Айлана чизиш", //'Draw a circle',
				marker: "Маркер чизиш" //'Draw a marker'
			}
		},
		handlers: {
			circle: {
				tooltip: {
					start: "Нажмите и потяните для рисования окружности" //'Click and drag to draw circle.'
				},
				radius: "Радиус" //'Radius'
			},
			circlemarker: {
				tooltip: {
					start: "Click map to place circle marker."
				}
			},
			marker: {
				tooltip: {
					start: "Кликните на карту для расположения маркера" //'Click map to place marker.'
				}
			},
			polygon: {
				tooltip: {
					start: "Чизишни бошлаш учун харитага босинг", //'Click to start drawing shape.',
					cont: "Давом эттириш учун тугмани босинг", //'Click to continue drawing shape.',
					end: "Тугатиш учун биринчи нуқтага босинг" //'Click first point to close this shape.'
				}
			},
			polyline: {
				error: "<strong>Ошибка:</strong> части линии не должны пересекаться!",
				tooltip: {
					start: "Кликните на карту для начала рисования", //'Click to start drawing line.',
					cont: "Кликните для продолжения рисования", //'Click to continue drawing line.',
					end: "Кликните на последнюю вершину для завершения линии" //'Click last point to finish line.'
				}
			},
			rectangle: {
				tooltip: {
					start: "Кликните и потяните для создания прямоугольника" //'Click and drag to draw rectangle.'
				}
			},
			simpleshape: {
				tooltip: {
					end: "Отпустите кнопку мыши для окончания рисования" //'Release mouse to finish drawing.'
				}
			}
		}
	},
	edit: {
		toolbar: {
			actions: {
				save: {
					title: "Ўзгаришни сақлаш", //'Save changes.',
					text: "Сақлаш" //'Save'
				},
				cancel: {
					title: "Таҳрирлашни тўхтатиш, барча қилинган ўзгаришларни бекор қилиш", //'Cancel editing, discards all changes.',
					text: "Бекор қилиш" //'Cancel'
				},
				clearAll: {
					title: "Барчасини ўчириш",
					text: "Барчасини ўчириш"
				}
			},
			buttons: {
				edit: "Таҳрирлаш", //'Edit layers.',
				editDisabled: "Таҳрирланадиган чизмалар мавжуд эмас", //'No layers to edit.',
				remove: "Ўчириш", //'Delete layers.',
				removeDisabled: "Ўчириладиган чизмалар мавжуд эмас" //'No layers to delete.'
			}
		},
		handlers: {
			edit: {
				tooltip: {
					text: "Объектни ўзгартири учун тепасини ёки маркерни ҳаракатлантиринг", //'Drag handles, or marker to edit feature.',
					subtext: "Дастлабки ҳолатга қайтиш учун бекор қилиш тугмасини босинг" //'Click cancel to undo changes.'
				}
			},
			remove: {
				tooltip: {
					text: "Ўчириш учун объектни босинг" //'Click on a feature to remove'
				}
			}
		}
	}
}
